/* eslint-disable no-console */
import type { ApiCdnLogRequest } from '@/pages/api/cdn-log';
import type { ApiLogRequest } from '@/pages/api/log';

import { IS_NODE_ENV_DEV, IS_SERVER } from '@/constants/env';
import { APP_SESSION_COOKIE_NAME } from '@/constants/product';
import { getBrowserCookie } from '@/core/features/cookies/cookies-service';

export type LoggerContext = {
    additionalData?: string;
    c24appversion?: null | string;
    debugNamespace?: string;
    host?: string;
    indiSession?: null | string;
    referrer?: string;
    serverOnly?: boolean;
    source?: ApiCdnLogRequest['source'] | ApiLogRequest['source'];
    stackTrace?: string;
    url?: string;
    userAgent?: string;
};

type LogType = keyof LoggerInterface;
export type LoggerMessage = Error | object | string;
type LoggerFunction = (message: LoggerMessage, context?: LoggerContext) => void;
export type LoggerInterface = {
    error: LoggerFunction;
    info: LoggerFunction;
    warn: LoggerFunction;
};

// fallback logger for client / dev when no logger should be used but provide the same interface
export const emptyLogger: LoggerInterface = {
    error: () => {},
    info: () => {},
    warn: () => {},
};

export const getLogPrefix = (): string[] => {
    const date = new Date();
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');
    const ms = date.getMilliseconds().toString().padStart(3, '0');

    return [`%c${hour}:${minute}:${second}:${ms}`, 'color: #ccc; font-size: 10px'];
};

// main logger (isomorphic)  that can log both server- and clientside
const getLogHandler =
    (logType: LogType): LoggerFunction =>
    async (message, context) => {
        if (IS_SERVER || (context?.serverOnly && IS_SERVER)) {
            const serverLogger = await (await import('./server-logger')).serverLogger;
            serverLogger[logType](message, context);
            return;
        }
        if (context?.serverOnly && !IS_NODE_ENV_DEV) {
            return;
        }

        sendLogMessage(logType, message, {
            additionalData: context?.additionalData,
            indiSession: context?.indiSession,
            source: 'client',
            url: location?.href,
        });
    };

export const logger: LoggerInterface = {
    error: getLogHandler('error'),
    info: getLogHandler('info'),
    warn: getLogHandler('warn'),
};

export default logger;

export const devLogger: LoggerInterface = IS_NODE_ENV_DEV ? logger : emptyLogger;

export const sendClientLogMessage = (type: LogType, message: string, context: LoggerContext) => {
    sendLogMessage(type, message, { ...context, source: 'client' });
};

export const sendMiddlewareLogMessage = (type: LogType, message: string, context: Omit<LoggerContext, 'source'>) => {
    logger[type](message, { ...context, source: 'middleware' });
};

// Used to send client and middleware logs to /api/log endpoint as a proxy
const sendLogMessage = async (
    type: LogType,
    message: object | string,
    { additionalData, host, indiSession, source, stackTrace, url }: LoggerContext,
) => {
    if (typeof message !== 'string') {
        try {
            // eslint-disable-next-line fp/no-mutation
            message = message.toString();
        } catch (error) {
            console.error('Error sending Log Message', error);
            return;
        }
    }

    if (message.includes('404')) {
        return;
    }

    if (IS_NODE_ENV_DEV) {
        console[type]('[dev] Logger:', message);
        try {
            if (!additionalData) {
                return;
            }
            console[type]('[dev] Logger: additionalData:', JSON.parse(additionalData));
        } catch (e) {}
        return;
    }

    const logHost = host ? `${host}/api/log` : '/api/log';

    await fetch(logHost, {
        body: JSON.stringify({
            additionalData: additionalData?.substring(0, 2000),
            indiSession: indiSession ?? IS_SERVER ? null : getBrowserCookie(APP_SESSION_COOKIE_NAME),
            message,
            source,
            stackTrace,
            type,
            url,
        }),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
    }).catch((error) => console.error('Error sending Log Message:', error));
};

export const stringifyLogMessage = (message: LoggerMessage): string => {
    try {
        if (message instanceof Error) {
            return message.stack ?? message.message;
        }
        if (typeof message === 'object') {
            return JSON.stringify(message);
        }
        return message;
    } catch (error) {
        return `Error serializing message in getSerializedMessage: ${error}`;
    }
};
