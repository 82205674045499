import type { DynamicPageMeta, ILayoutId } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { SsrProps } from '@/core/features/app/ssr-props';
import type { CookiesMapped } from '@/core/features/cookies/cookies-service';

import { getDeviceInfo, getDeviceOSMapped, getDeviceoutputFromCookies } from '@/core/features/cookies/cookies-service';
import { getURL } from '@/core/utils/url';

type AppPromotionState = {
    cookiesMapped: CookiesMapped;
    layoutId: ILayoutId | null;
    okResponse: boolean;
    settings: DynamicPageMeta['settings'];
};

type AppSplashState = AppPromotionState & {
    url: string;
};

const ADJUST_TRACKER = '19qm4rr9';
const ADJUST_FALLBACK = 'https://www.check24.de/app/';
const INDI_PRODUCTION_HOST = 'individualreisen.check24.de';
const TRAVEL_TOGGLE_SEARCH_PARAM = 'travelToggle';

const minSupportedOSVersions = {
    android: 8,
    ios: 15,
};
const unsupportedDeviceBrands = ['Huawei'];

function addTrackingParameters(url: URL, tracking: SsrProps['trackingMeta']): URL {
    const { pid, tid, wpset } = tracking ?? {};

    if (wpset) {
        url.searchParams.set('wpset', wpset);
    }
    if (tid) {
        url.searchParams.set('tid', tid);
    }
    if (pid) {
        url.searchParams.set('pid', pid);
    }

    return url;
}

// Docs: https://confluence.check24.de/pages/viewpage.action?spaceKey=C24MOCO&title=How+To%3A+Generate+Adjust+Links
export function getAdjustLink(
    deepLinkUrl: string,
    tracking: SsrProps['trackingMeta'],
    cookiesMapped: CookiesMapped,
): string {
    const parsedDeepLinkUrl = getURL(deepLinkUrl);
    addTrackingParameters(parsedDeepLinkUrl, tracking);
    const targetLinkBase = `${INDI_PRODUCTION_HOST}${parsedDeepLinkUrl.pathname}${parsedDeepLinkUrl.search}`;
    const deepLink = `c24-app-vergleiche://${targetLinkBase}`;

    const { deviceOS } = getDeviceInfo(cookiesMapped.deviceinfo, cookiesMapped.indiSession);
    const mappedOS = getDeviceOSMapped(deviceOS);

    if (mappedOS === 'android') {
        const adjustDeepLink = getURL(`https://app.adjust.com/${ADJUST_TRACKER}`);
        adjustDeepLink.searchParams.set('deep_link', deepLink);
        adjustDeepLink.searchParams.set('fallback', ADJUST_FALLBACK);
        return adjustDeepLink.href;
    }

    const adjustUniversalLink = getURL(`https://9a6e.adj.st/${targetLinkBase}`);
    adjustUniversalLink.searchParams.set('adjust_t', ADJUST_TRACKER);
    adjustUniversalLink.searchParams.set('adjust_deep_link', deepLink);
    adjustUniversalLink.searchParams.set('adjust_fallback', ADJUST_FALLBACK);

    return adjustUniversalLink.href;
}

function isAppPromotionAvailable({ cookiesMapped, okResponse, settings }: AppPromotionState): boolean {
    if (!okResponse) {
        return false;
    }

    const {
        deviceName,
        deviceOS,
        version: _version,
    } = getDeviceInfo(cookiesMapped.deviceinfo, cookiesMapped.indiSession);
    const version = parseFloat(_version);
    const mappedOS = getDeviceOSMapped(deviceOS);

    const isDisabled = !!settings?.['disableAppBanner'];
    const isMobile = getDeviceoutputFromCookies(cookiesMapped) === 'mobile';
    const isDeviceBrandSupported = !unsupportedDeviceBrands.some((deviceBrand) => deviceName.includes(deviceBrand));
    const isDeviceOSSupported = mappedOS !== 'other' && version >= minSupportedOSVersions[mappedOS];

    return !isDisabled && isMobile && isDeviceBrandSupported && isDeviceOSSupported;
}

export function isAppBannerAvailable({ cookiesMapped, layoutId, okResponse, settings }: AppPromotionState): boolean {
    if (!!settings?.['hideAppBanner']) {
        return false;
    }
    if (layoutId === 'Mobile_S2_V1') {
        return false;
    }

    const isGenerallyAvailable = isAppPromotionAvailable({ cookiesMapped, layoutId, okResponse, settings });

    const isC24SabCookiePresent = !!cookiesMapped.c24sab;

    return isGenerallyAvailable && !isC24SabCookiePresent;
}

export function isAppSplashAvailable({ cookiesMapped, layoutId, okResponse, settings, url }: AppSplashState): boolean {
    const isGenerallyAvailable = isAppPromotionAvailable({ cookiesMapped, layoutId, okResponse, settings });
    const isC24SplashCookiePresent = !!cookiesMapped.c24splash;
    const isIndiSplashCookiePresent = !!cookiesMapped.indiSplash;
    const isTravelToggleParamPresent = getURL(url).searchParams.has(TRAVEL_TOGGLE_SEARCH_PARAM);

    return (
        isGenerallyAvailable && !isC24SplashCookiePresent && !isIndiSplashCookiePresent && !isTravelToggleParamPresent
    );
}
