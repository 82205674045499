// eslint-disable-next-line import-rules/disallow-paths
import type {
    DehydratedState,
    InfiniteData,
    Query,
    QueryFunction,
    QueryKey,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query';

// eslint-disable-next-line import-rules/disallow-paths
import {
    useQuery as _useQuery,
    HydrationBoundary as Hydrate,
    QueryClient,
    QueryClientProvider,
    dehydrate,
    useInfiniteQuery,
    useIsFetching,
    useMutation,
    useQueries,
    useQueryClient,
} from '@tanstack/react-query';

export type { DehydratedState, InfiniteData, Query, QueryKey, UseQueryResult };

export {
    Hydrate,
    QueryClient,
    QueryClientProvider,
    dehydrate,
    useInfiniteQuery,
    useIsFetching,
    useMutation,
    useQueries,
    useQueryClient,
};

type Options<TQueryFnData, TError, TData = TQueryFnData> = Omit<
    UseQueryOptions<TQueryFnData, TError, TData, QueryKey>,
    'queryFn' | 'queryKey'
>;

/**
 * useQuery wrapper to enable empty queryKeys for transitional states
 */
export const useQuery = <TQueryFnData, TError>(
    queryKey: QueryKey | null,
    queryFn: QueryFunction<TQueryFnData, QueryKey> | null,
    options: Options<TQueryFnData, TError>,
) => {
    /* eslint-disable fp/no-mutation */
    if (queryKey === null) {
        queryKey = [];
        options.enabled = false;
    }
    if (queryFn === null) {
        queryFn = () => Promise.resolve(['noop']) as never;
        options.enabled = false;
    }
    /* eslint-enable fp/no-mutation */

    return _useQuery({ queryFn, queryKey, ...options });
};

export const compareQueryKeys = (key1: QueryKey, key2: QueryKey): boolean => {
    return key1.join() === key2.join();
};
