// this is the NEXT API, NOT the INDI backend api! -> https://nextjs.org/docs/api-routes/introduction

import type { AxiosResponse } from 'axios';

import axios from 'axios';

import { INDI_CLIENT_VERSION_HEADER, JsonRequestHeaders, JsonldRequestHeaders } from '@/constants/request-headers';
import { getURL } from '@/core/utils/url';

export function clientSideApiGetRequest<ResponseType>(
    path: string,
    abortSignal?: AbortSignal,
    headers?: Record<string, string>,
): Promise<AxiosResponse<ResponseType>> {
    const url = getURL(`/api${path}`).href;
    return axios.get<ResponseType>(url, {
        headers: {
            ...JsonldRequestHeaders,
            ...headers,
            ...INDI_CLIENT_VERSION_HEADER,
        },
        signal: abortSignal,
    });
}

export function clientSideApiPostRequest<ResponseType>(
    path: string,
    request: Object,
    abortSignal?: AbortSignal,
    headers?: Record<string, string>,
): Promise<AxiosResponse<ResponseType>> {
    return axios.post<ResponseType>(`/api${path}`, request, {
        headers: {
            ...JsonRequestHeaders,
            ...headers, //
            ...INDI_CLIENT_VERSION_HEADER,
        },
        signal: abortSignal,
    });
}

export function clientSideApiPutRequest<ResponseType, RequestType = Object>(
    path: string,
    request: RequestType,
    abortSignal?: AbortSignal,
    headers?: Record<string, string>,
): Promise<AxiosResponse<ResponseType>> {
    return axios.put<ResponseType>(`/api${path}`, request, {
        headers: {
            ...JsonRequestHeaders,
            ...headers, //
            ...INDI_CLIENT_VERSION_HEADER,
        },
        signal: abortSignal,
    });
}

export function clientSideApiDeleteRequest<ResponseType = Object>(
    path: string,
    abortSignal?: AbortSignal,
    headers?: Record<string, string>,
): Promise<AxiosResponse<ResponseType>> {
    return axios.delete<ResponseType>(`/api${path}`, {
        headers: {
            ...JsonRequestHeaders,
            ...headers, //
            ...INDI_CLIENT_VERSION_HEADER,
        },
        signal: abortSignal,
    });
}
