import type { SsrProps } from '@/core/features/app/ssr-props';

import React from 'react';

import { WIREFRAME_CONSENT_BANNER_CLASS_MOBILE } from '@packages/wireframe/src/constants';

import { getAdjustLink, isAppSplashAvailable } from '@/core/features/app-promotion/app-promotion-service';
import { setBrowserCookie } from '@/core/features/cookies/cookies-service';
import useWireframeConsentBannerVisibleListener from '@/core/features/wireframe/wireframe-manipulation/consent-banner/use-wireframe-consent-banner-visible';
import AppSplash from '@/features/app-splash/app-splash';

export default function AppSplashWrapper({
    cookiesMapped,
    layoutId,
    okResponse,
    settings,
    ssrUrl,
    trackingMeta,
}: SsrProps) {
    const [isVisible, setVisible] = React.useState(true);
    const isConsentBannerVisible = useWireframeConsentBannerVisibleListener(cookiesMapped, okResponse);

    if (!isAppSplashAvailable({ cookiesMapped, layoutId, okResponse, settings, url: ssrUrl })) {
        return;
    }

    const ctaLink = getAdjustLink(ssrUrl, trackingMeta, cookiesMapped);

    const onClose = () => {
        const body = document.querySelector('body');

        if (body && isConsentBannerVisible) {
            body.classList.remove(WIREFRAME_CONSENT_BANNER_CLASS_MOBILE);
            setTimeout(() => body.classList.add(WIREFRAME_CONSENT_BANNER_CLASS_MOBILE), 500);
        }

        setVisible(false);
        setBrowserCookie('indiSplash', '1', { isTopLevel: false });
    };

    return (
        <>
            {isVisible && (
                <AppSplash
                    ctaLink={ctaLink}
                    onClose={onClose}
                />
            )}
        </>
    );
}
