import type { SsrProps } from '@/core/features/app/ssr-props';
import type { Deviceoutput } from '@/core/features/cookies/cookies-service';

import React from 'react';

import NextHead from 'next/head';

import { useMetaDataState } from '@/core/features/a-dynamic-page/hooks/meta-data-state';
import { useClientUrl } from '@/core/features/app/app-atoms';
import { getCdnImageUrl } from '@/core/features/image-v2/image-utils';
import {
    type WebViewAppMeta as AppMetaType,
    type CrawlerMeta as CrawlerMetaType,
} from '@/core/features/page/page-types';
import { getUrlByPath, getUrlPath } from '@/core/utils/url';

const noindex = 'noindex,nofollow,c24noindex,c24nofollow';

export default function HeadDynamic(props: SsrProps) {
    const metaData = useMetaDataState();
    const { deviceoutput } = props.cookiesMapped;

    if (props.isDynamicPageWidget) {
        return;
    }

    return (
        <>
            {metaData?.crawlerMeta ? (
                <CrawlerMeta crawlerMeta={metaData.crawlerMeta} />
            ) : (
                // fallback if crawler meta is missing
                <CrawlerMetaFallback />
            )}
            {metaData?.appMeta && (
                <AppMeta
                    appMeta={metaData.appMeta}
                    deviceoutput={deviceoutput}
                />
            )}
            {
                // fallback title (404)
                !metaData?.crawlerMeta && !metaData?.appMeta && (
                    <NextHead>
                        <title>CHECK24 Individualreisen</title>
                    </NextHead>
                )
            }
        </>
    );
}

const CrawlerMetaFallback = () => {
    const [clientUrl] = useClientUrl();

    return (
        <NextHead>
            <meta
                content={noindex}
                name={'robots'}
            />
            <link
                href={getUrlByPath(getUrlPath(clientUrl)).toString()} // make it a full url withouth query params
                rel={'canonical'}
            />
        </NextHead>
    );
};

const CrawlerMeta = (props: { crawlerMeta: CrawlerMetaType }) => {
    const { crawlerMeta } = props;
    const socialMediaImage = getCdnImageUrl(crawlerMeta.imageUrl, 1000, 500);
    const [clientUrl] = useClientUrl();
    const hasUserQuery = clientUrl.includes('?');

    return (
        <NextHead>
            <title>{crawlerMeta.title}</title>
            <meta
                content={crawlerMeta.title}
                name={'title'}
            />
            <meta
                content={crawlerMeta.description}
                lang={'de'}
                name={'description'}
            />
            <meta
                content={crawlerMeta.keywords}
                name={'keywords'}
            />
            <meta
                content={hasUserQuery ? noindex : crawlerMeta.robots}
                name={'robots'}
            />
            <link
                href={getUrlPath(crawlerMeta.canonical ?? '')}
                rel={'canonical'}
            />
            {/* facebook open-graph */}
            <meta
                content={crawlerMeta.title}
                property={'og:title'}
            />
            <meta
                content={crawlerMeta.description}
                property={'og:description'}
            />
            <meta
                content={socialMediaImage}
                property={'og:image'}
            />
            {/* twitter */}
            <meta
                content={'summary_large_image'}
                property={'twitter:card'}
            />
            <meta
                content={crawlerMeta.url}
                property={'twitter:url'}
            />
            <meta
                content={crawlerMeta.title}
                property={'twitter:title'}
            />
            <meta
                content={crawlerMeta.description}
                property={'twitter:description'}
            />
            <meta
                content={socialMediaImage}
                property={'twitter:image'}
            />
        </NextHead>
    );
};

const AppMeta = (props: { appMeta: AppMetaType; deviceoutput: Deviceoutput }) => {
    React.useEffect(() => {
        if (!window.C24App) {
            return;
        }

        window.C24App.setProduct({ productSSO: props.appMeta.productName, title: props.appMeta.title });
    }, [props]);

    if (!props.deviceoutput.includes('app')) {
        return null;
    }
    return (
        <NextHead>
            <meta
                content={props.appMeta.title}
                name={'title'}
            />
        </NextHead>
    );
};
