import type React from 'react';

import {
    // isAppPluginEnabled,
    triggerNativeAppMethod,
} from '@/core/features/native-app-bridge/native-app-bridge-service';

/**
 * Docs: https://c24-indi.atlassian.net/browse/INDI-2483
 */
export const APP_NATIVE_LINK_PREFIX = 'appnative:';

export const willForwardToAppNative = (href: string): boolean => href.indexOf(APP_NATIVE_LINK_PREFIX) === 0;

export const forwardLinkUrlToAppNative = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    href: string,
): void => {
    event.preventDefault();
    triggerAppNativeLink(href);
};

export const triggerAppNativeLink = (linkUrl: string): void => {
    triggerNativeAppMethod('triggerNativeNavigation', { linkUrl });
};

export const replaceAppNativeLinkUrl = (href: string): string => href.replace(APP_NATIVE_LINK_PREFIX, '');
